import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhoneAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"

const ContactInfo = () => {
  return (
    <ul>
      <li>
        <strong>Email:</strong> <br />{" "}
        <FontAwesomeIcon color="#04BD92" icon={faEnvelope} />{" "}
        <a
          style={{ textDecoration: "none", color: "inherit" }}
          href="mailto:me@jephtecolin.com"
        >
          me@jephtecolin.com
        </a>
      </li>
    </ul>
  )
}

export default ContactInfo
