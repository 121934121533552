import React from "react"
import Layout from "../components/layout"
import { navigate } from "gatsby-link"
import SEO from "../components/seo"
import styles from "./contact.module.scss"
import ContactInfo from "../components/contactInfo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const ContactPage = () => {
  const [state, setState] = React.useState({})

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }
  return (
    <div>
      <Layout>
        <SEO title="Contact" />
        <h2 className={styles.title}>Let's talk here...</h2>
        <ContactInfo />
        <form
          name="contact"
          method="post"
          action="/thanks/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="contact" />
          <p hidden>
            <label>
              Don’t fill this out:{" "}
              <input name="bot-field" onChange={handleChange} />
            </label>
          </p>
          <p>
            <label>
              <input
                className={styles.field}
                type="text"
                name="name"
                placeholder="Your Name..."
                onChange={handleChange}
              />
            </label>
          </p>
          <p>
            <label>
              <input
                className={styles.field}
                type="email"
                name="email"
                placeholder="Your Email..."
                onChange={handleChange}
              />
            </label>
          </p>
          <p>
            <label>
              <textarea
                className={styles.field}
                rows="5"
                name="message"
                placeholder="Type your message..."
                onChange={handleChange}
              />
            </label>
          </p>
            <button className={styles.buttonGradient} type="submit">
              <FontAwesomeIcon icon={faPaperPlane} /> &nbsp; Send Email
            </button>
        </form>
      </Layout>
    </div>
  )
}

export default ContactPage
